import { lighten } from 'polished'

import utils from './utils'

function processColors<Tobj, T extends Tobj & Record<string, string>>(
  colors: T
): Record<keyof T, ReturnType<typeof utils.getColorRange>> {
  return Object.entries(colors).reduce(
    (acc, [key, value]) => {
      acc[key] = utils.getColorRange(value)

      return acc
    },
    {} as Record<string, ReturnType<typeof utils.getColorRange>>
  ) as Record<keyof T, ReturnType<typeof utils.getColorRange>>
}

export const primaryColors = {
  ink: '#202023',
  grass: '#009F20',
}

export const fixedColors = {
  transparent: 'transparent',
  ghost: '#FFF',
  cornflower: '#7B61FF',
  crimson: '#F85563',
  unfocus: lighten(0.3, primaryColors.ink),
  disabled: lighten(0.65, primaryColors.ink),
}

export const supportColors = {
  cotton: '#F9F8F8',
  pillow: '#F4F4F4',
  ocean: '#0052B4',
  lake: '#9AB5CB',
  sky: '#DCEAF6',
}

export const tailwindColors = Object.freeze({
  ...processColors(primaryColors),
  ...processColors(supportColors),
  ...fixedColors,
})

export const colors = {
  ...primaryColors,
  ...supportColors,
  ...fixedColors,
}

export default colors

const colorChoises = {
  ...primaryColors,
  ...supportColors,
  crimson: fixedColors.crimson,
  cornflower: fixedColors.cornflower,
}

export type colorType = keyof typeof colorChoises | 'ghost'
