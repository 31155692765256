import colors from 'components/AdamTheme/theme/colors'

export const shopifyToImgix = (src?: string, aspect?: number, padding?: number | null): string => {
  const width = 2000
  const background = colors.pillow.replace('#', '')

  const urlRoot = src?.replace('https://cdn.shopify.com/s/files', process.env.NEXT_PUBLIC_IMGIX)

  const url = !aspect
    ? `${urlRoot}?fm=pjpg&bg=${background}`
    : `${urlRoot}?fm=pjpg&bg=${background}&w=${width}&h=${width * (aspect / 100)}`

  if (padding) {
    return `${url}&fit=fill&fill-color=${background}&fill=solid&pad=${Math.round(width * padding)}`
  }

  return `${url}&fit=crop`
}
