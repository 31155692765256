import { lighten, darken, getLuminance } from 'polished'

import settings from './settings'

const utils = Object.freeze({
  getColorRange: (color: string) => {
    return {
      DEFAULT: color,
      hover: utils.getHoverFromColor(color),
      active: utils.getActiveFromColor(color),
    }
  },
  getHoverFromColor: (color: string) => {
    const luminance = getLuminance(color)

    if (luminance > 0.9) {
      return darken(settings.hover * 0.7, color)
    }

    const alterColorFunction = luminance > 0.1 ? darken : lighten
    return alterColorFunction(settings.hover, color)
  },
  getActiveFromColor: (color: string) => {
    const luminance = getLuminance(color)

    if (luminance > 0.9) {
      return darken(settings.active * 0.7, color)
    }

    const alterColorFunction = luminance > 0.1 ? darken : lighten
    return alterColorFunction(settings.active, color)
  },
  convertViewports: <Tobj>(
    viewports: Record<keyof Tobj, number>,
    keyPrefix?: string
  ): Record<keyof typeof viewports | string, string> => {
    return Object.entries(viewports).reduce(
      (acc, [key, value]) => {
        const newKey = keyPrefix ? `${keyPrefix}${key}` : (key as keyof typeof viewports)
        acc[newKey] = `${value}px`

        return acc
      },
      {} as Record<keyof Tobj | string, string>
    )
  },
  generateGridValues: (start: number, end: number) => {
    return new Array(end - start)
      .fill(1)
      .map((_, index) => index + start)
      .reduce(
        (sum, cur) => {
          sum[cur.toString()] = cur.toString()
          return sum
        },
        {} as Record<string, string>
      )
  },
})

export default utils
