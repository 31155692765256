'use client'

import NextImage from 'next/image'
import { useState, useEffect, useRef } from 'react'

import { shopifyToImgix } from 'core/utils/img'
import mergeClasses from 'core/utils/mergeClasses'

import Icon from 'components/Icon'

type crop = {
  ar?: [number, number] | null
  x: number
  y: number
}

export function processImageSource(
  src?: string,
  crop?: crop | null,
  aspectRatio?: [number, number] | null,
  padding?: number | null
): string {
  if (!src) {
    return ''
  }
  const imgRegex = /shopify\.com/g
  const shouldConvert = src?.match(imgRegex)
  const imgixRegex = /imgix\.net/g
  const leaveAsIs = src?.match(imgixRegex)

  const aspectA = aspectRatio?.[0] || 0
  const aspectB = aspectRatio?.[1] || 0
  const ratio = (aspectRatio?.length || 0) > 0 ? (aspectB / aspectA) * 100 : 0

  return leaveAsIs || src?.includes('svg')
    ? src
    : shouldConvert
      ? shopifyToImgix(src, ratio, padding)
      : `${src}?fm=jpg${!!crop ? `&fit=crop&crop=focalpoint&fp-x=${crop.x}&fp-y=${crop.y}` : ''}${
          !!crop?.ar ? `&ar=${crop.ar[0]}:${crop.ar[1]}` : ''
        }`
}

export default function Image({
  src: _src,
  quality = 65,
  crop,
  objectFit = 'cover',
  objectPosition,
  alt = '',
  sizes,
  priority = false,
  aspectRatio,
  padding,
  blur,
  className,
  style,
  children,
  load = true,
}: {
  src: string
  quality?: number
  crop?: crop | null
  objectFit?: 'contain' | 'cover' | 'fill'
  objectPosition?: string
  alt?: string | null
  sizes?: string
  priority?: boolean
  aspectRatio?: string
  padding?: number | null
  blur?: boolean
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  load?: boolean
}): JSX.Element {
  const src = processImageSource(
    _src,
    crop,
    aspectRatio?.split('/').map((str) => Number(str)) as [number, number],
    padding
  )

  const timoutRef = useRef<NodeJS.Timeout | null>(null)
  const [error, setError] = useState(false)
  const [srcCache, setSrcCache] = useState<null | string>(null)
  const [srcLoaded, setSrcLoaded] = useState<false | string>(src)
  const isLoaded = srcLoaded === src

  useEffect(
    () => () => {
      if (timoutRef.current && blur) clearTimeout(timoutRef.current)
    },
    []
  )

  useEffect(() => {
    if (srcCache && srcLoaded !== src) {
      setSrcLoaded(false)
    } else {
      setSrcCache(src)
    }
  }, [src, srcLoaded])

  return (
    <div
      className={mergeClasses(`relative flex h-full w-full overflow-hidden`, className)}
      style={{
        ...style,
      }}
    >
      {error ? (
        <Icon
          name="imageError"
          className="absolute left-2/4 top-2/4 -translate-x-1/2 -translate-y-1/2"
        />
      ) : (
        <>
          {blur && (
            <NextImage
              className="blur-md transition-all duration-200 ease-in"
              quality={50}
              src={src}
              alt={alt || ''}
              sizes="50px"
              priority={priority}
              fetchPriority="high"
              loading="eager"
              style={{
                transform: 'scale(1.02)',
                objectFit,
                objectPosition,
                aspectRatio: aspectRatio ? `${aspectRatio?.[0]}/${aspectRatio?.[1]}` : '',
              }}
              fill
            />
          )}
          {load && (
            <NextImage
              className="transition-all duration-200 ease-in"
              quality={quality}
              sizes={sizes || '1800px'}
              src={src}
              alt={alt || ''}
              fill
              style={{
                objectFit,
                objectPosition,
                aspectRatio: aspectRatio ? `${aspectRatio?.[0]}/${aspectRatio?.[1]}` : '',
              }}
              priority={!blur && priority}
              fetchPriority={!blur && priority ? 'high' : 'auto'}
              onLoad={() => {
                if (timoutRef.current && blur) clearTimeout(timoutRef.current)
                setSrcLoaded(src)
              }}
              onLoadStart={() => {
                if (!isLoaded && blur) {
                  timoutRef.current = setTimeout(() => setSrcLoaded(false), 100)
                }
              }}
              onError={() => setError(true)}
            />
          )}
        </>
      )}
      {children}
    </div>
  )
}
